<template>
  <div class="holder">
    <SmallNav />
    <div v-if="hideDiv">
      <PageLoader></PageLoader>
    </div>
    <div
      class="row row_check"
      :class="[subscribed == 'YES' ? 'flip_column' : '']"
    >
      <div v-if="!hideDiv" class="container mt-5 pl-md-5 pr-md-5">
        <div class="card">
          <div class="">
            <div class="row row_wrap">
              <div class="col-md-4 pr-md-0">
                <div class="payment_wrap">
                  <div class="sub_amount">
                    <h3>
                      &#8358;5,000
                      <img
                        v-if="subscribed === 'YES'"
                        src="../assets/images/green-check-round.svg"
                        alt="paid"
                      />
                    </h3>
                    <h4>&#8358;10,000</h4>
                  </div>
                  <div class="payment">
                    <paystack
                      :disabled="subscribed === 'YES'"
                      class="paystackBtn"
                      :amount="amount * 100"
                      :email="email"
                      :paystackkey="PUBLIC_KEY"
                      :reference="reference"
                      :callback="processPayment"
                      :close="close"
                    >
                      <div class="paystack_div">
                        <img src="../assets/images/paystack-blue.svg" alt="" />
                        <small>
                          Paystack
                        </small>
                        <img
                          v-if="subscribed === 'YES'"
                          src="../assets/images/luck.svg"
                          alt="luck"
                        />
                      </div>
                    </paystack>

                    <div class="type_paystack" @click="payFromSafe()">
                      <img src="../assets/images/safe.svg" alt="" />
                      <small>Pay with SAFE</small>
                      <img
                        v-if="subscribed === 'YES'"
                        src="../assets/images/luck.svg"
                        alt="luck"
                      />
                    </div>

                    <div class="type_paystack">
                      <img src="../assets/images/flutter-yellow.svg" alt="" />
                      <small>Flutterwave</small>
                      <img src="../assets/images/luck.svg" alt="luck" />
                    </div>

                    <div class="type_paystack">
                      <img src="../assets/images/enaira-green.svg" alt="" />
                      <small>e-Naira</small>
                      <img src="../assets/images/luck.svg" alt="luck" />
                    </div>

                    <div class="type_paystack" @click="payFromBank()">
                      <img
                        src="../assets/images/double-arrow-blue.svg"
                        alt=""
                      />
                      <small>Bank Transfer</small>
                      <img
                        v-if="subscribed === 'YES'"
                        src="../assets/images/luck.svg"
                        alt="luck"
                      />
                    </div>
                  </div>
                  <div class="sub_quote">
                    <h6 v-if="subscribed === 'YES'">
                      See what you get as a subscriber
                    </h6>
                  </div>
                </div>
              </div>
              <div class="col-md-8 pl-md-0">
                <div class="enjoy_sub">
                  <div class="enjoy_holder">
                    <div class="enjoy_text_wrap pl-md-5 pl-sm-2">
                      <h2>
                        enJOY <br />
                        your <br />
                        LIFE
                      </h2>
                      <p>...we’ve got you covered.</p>
                    </div>

                    <div>
                      <img src="../assets/images/sub-img.svg" alt="sub" />
                      <img src="../assets/images/sub-iconhand.svg" alt="hand" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Benefits of subscribing -->
      <div v-if="!hideDiv" class="container pl-md-5 pr-md-5">
        <div class="sub_benefit_holder">
          <div class="sub_benefit_header">
            <h4>Subscriber's Only Benefit</h4>
          </div>
          <div class="benefit_body">
            <div class="benefit">
              <p>1</p>
              <div>
                <h4>
                  Rent Financing:
                </h4>
                <h6>
                  Every Subscriber has the opportunity to apply for Rent
                  Financing, A subscriber has an opportunity to access rent
                  Financing by way of flexible rent payment to Shelta while
                  SHELTA pays the LANDLORD the full annual rent. But the
                  Subscriber must fulfill all the mandatory requirements to be
                  eligible and qualified.
                </h6>
              </div>
            </div>
            <div class="benefit">
              <p>2</p>
              <div>
                <h4>
                  Zero Agency and Legal fees:
                </h4>
                <h6>
                  These benefits are exclusively for qualified subscribers who
                  would take up properties that are under the management of
                  SHELTA
                </h6>
              </div>
            </div>
            <div class="benefit">
              <p>3</p>
              <div>
                <h4>
                  Exclusive Property Inspection:
                </h4>
                <h6>
                  A subscriber shall be entitled to free inspection of any
                  property he/she is interested in renting or buying without
                  paying Inspection fee.
                </h6>
              </div>
            </div>
            <div class="benefit">
              <p>4</p>
              <div>
                <h4>
                  First consideration:
                </h4>
                <h6>
                  Every already existing Subscriber would be considered first
                  before new applicants or unsubscribed tenants for Rent of any
                  SHELTA PROPERTIES, Rent Financing, PROPERTY INSPECTION etc.
                </h6>
              </div>
            </div>
            <div class="benefit">
              <p>5</p>
              <div>
                <h4>
                  Tenant Verification:
                </h4>
                <h6>
                  Every subscriber coming under the profile of tenants undergoes
                  a verification process for essential profiling and
                  consideration
                </h6>
              </div>
            </div>
            <div class="benefit">
              <p>6</p>
              <div>
                <h4>
                  Notification for new property listing:
                </h4>
                <h6>
                  Every subscriber gets notifications for new properties listed
                  on the Shelta website, sent directly to their mail boxes.
                </h6>
              </div>
            </div>
            <div class="notes">
              <div>
                <h4>
                  NOTE:
                </h4>
                <h6>
                  <span>*</span>
                  There is a Subscription fee Payable by every Shelta platform
                  user, It's a non-refundable one-off payment which shall also
                  be automated.
                </h6>
                <h6>
                  <span>*</span>
                  Every aspiring Tenant must subscribe to be Amongst those to be
                  considered. The subscription fee is mandatory for every new
                  and existing tenant.
                </h6>
                <h6>
                  <span>*</span>
                  Subscription does not Guarantee eligibility for rent
                  Financing, Every applicant for rent Financing must meet all
                  the requirements in order to be eligible.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Benefits of sunscribing ends -->

    <!-- Modal to confirm payment from safe -->
    <div
      class="modal fade"
      id="paymentSafe"
      tabindex="-1"
      role="dialog"
      aria-labelledby="showpayment"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <p class="subConfirm">Subscription Confirmation</p>
          </div>
          <div class="proceed-text">
            <h6>Do you want to proceed?</h6>
          </div>
          <div class="confrim-safe-btn">
            <button class="btn" data-dismiss="modal" aria-label="Close">
              Cancel
            </button>
            <button class="btn" @click="subscribeWithSafe">
              Pay
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="loader1"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal to confirm payment from safe ends -->

    <!-- Modal for bank statment upload begins -->
    <div
      class="modal fade"
      id="showpayment"
      tabindex="-1"
      role="dialog"
      aria-labelledby="showpayment"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header payment_header_holder">
            <img
              v-if="headerText2"
              @click="switchView(0)"
              class="back__arrow"
              src="../assets/images/arrow2-white.svg"
              alt=""
            />
            <img
              v-if="headerText3"
              @click="switchView(1)"
              class="back__arrow"
              src="../assets/images/arrow2-white.svg"
              alt=""
            />
            <h5
              v-if="headerText1"
              class="modal-title payment-header"
              id="exampleModalLongTitle"
            >
              Pay My Rent
            </h5>
            <h5
              v-if="headerText2"
              class="modal-title payment-header"
              id="exampleModalLongTitle"
            >
              Proof of payment upload.
            </h5>
            <h5
              v-if="headerText3"
              class="modal-title payment-header"
              id="exampleModalLongTitle"
            >
              Call Shelta.
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="close_times">&times;</span>
            </button>
          </div>
          <div id="onee" class="modal-body">
            <div class="">
              <div class="dot-holder">
                <span class="step active"></span>
                <span class="step"></span>
                <span class="step"></span>
                <!-- <span class="step"></span> -->
              </div>
              <div class="double_arrow_holder">
                <img src="../assets/images/double-arrow-blue.svg" alt="back" />
                <p>Bank Transfer</p>
              </div>
              <div class="form-outter">
                <div class="first-step">
                  <div class="number__holder">
                    <p class="span-count">1</p>
                    <p>
                      Transfer the amount due to the acount number below
                    </p>
                  </div>

                  <div class="sheltaAccount__details">
                    <div>
                      <p>Amount:</p>
                      <p>
                        &#8358;5,000.
                      </p>
                    </div>
                    <div>
                      <p>Account Name:</p>
                      <p>Shelta Panacea Limited</p>
                    </div>
                    <div>
                      <p>Account Number :</p>
                      <p>0708490113</p>
                    </div>
                    <div>
                      <p>Bank :</p>
                      <p>Guarantee Trust Bank</p>
                    </div>
                  </div>
                  <div class="number__holder second__number">
                    <p class="span-count">2</p>
                    <p>
                      After the transfer is completed, please keep your payment
                      receipt by screenshot or photo and upload it in the next
                      step.
                    </p>
                  </div>

                  <div class="desclaimer__text">
                    <p>
                      Kindly note that your rent starts running 48 hours after
                      your payment is approved
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-foot">
              <button
                @click="switchView(1)"
                type="button"
                class="btn next-btn btn-block"
              >
                Next
              </button>
            </div>
          </div>

          <div id="twoo" class="modal-body">
            <div class="container-fluid">
              <div class="dot-holder">
                <span class="step"></span>
                <span class="step active"></span>
                <span class="step"></span>
              </div>
              <div class="form-outter">
                <div class="second-step">
                  <p class="info__text">
                    Please upload the payment receipt so that we can find your
                    payment.
                  </p>
                  <!-- <div class="dateOf__payment">
                    <label for="">Date of payment <span>*</span></label>
                    <input
                      class="form-control"
                      name="date"
                      type="date"
                      placeholder="Select date of payment"
                    />
                  </div> -->

                  <div class="upload__file">
                    <div class="dropzone">
                      <input
                        @change="selectFile"
                        class="input-file"
                        ref="file"
                        type="file"
                      />
                      <img src="../assets/images/file-upload.svg" alt="" />
                      <img
                        v-if="fileselected"
                        class="actual_image"
                        :src="filePath"
                        alt="reciept"
                      />
                      <p
                        v-if="!uploading && !fileselected"
                        class="call-to-action"
                      >
                        Drag and drop or
                        <span class="browse__span">browse</span> your files
                      </p>
                      <p
                        v-if="uploading"
                        class="call-to-action"
                        style="color: green;"
                      >
                        Uploding your reciept...
                      </p>
                      <!-- <p v-if="uploading" class="progress-bar">
                        <progress
                          class="progress is-primary"
                          :value="progress"
                          max="100"
                          >{{ progress }} %</progress
                        >
                      </p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p v-if="uploading" class="call-to-action" style="color: green;">
              Uploding your reciept...
            </p>
            <div class="modal-foot">
              <!-- switchView(2) -->
              <button
                @click="sendFile"
                type="button"
                class="btn next-btn btn-block"
              >
                SUBMIT
                <i
                  class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                  v-if="loading"
                ></i>
              </button>
            </div>
          </div>

          <div id="threee" class="modal-body">
            <div class="container-fluid">
              <div class="dot-holder">
                <span class="step"></span>
                <span class="step"></span>
                <span class="step active"></span>
              </div>
              <div class="form-outter">
                <div class="third-step">
                  <img src="../assets/images/rent-call.svg" alt="" />
                  <p>
                    Submited successfully ! We will confirm your payment and
                    update your status within 48 hours. For enquiries please
                    call our customer service agent on :
                  </p>
                </div>
              </div>
            </div>
            <div class="modal-foot">
              <button type="button" class="btn call-btn">
                <a href="tel:2348061796909">
                  <img src="../assets/images/phone-conect.svg" alt="" />
                  08061796909
                </a>
              </button>
            </div>
          </div>

          <!-- <div class="modal-footer modal-foot">
            
            <button type="button" class="btn next-btn btn-block">Next</button>
          </div> -->
        </div>
      </div>
    </div>
    <!-- Modal for bank statment upload ends -->
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import { Service } from "../store/service";
import PageLoader from "./PageLoader.vue";
import paystack from "vue-paystack";
import { staticdata } from "../../src/data";
const Api = new Service();
import $ from "jquery";
export default {
  name: "Subscription",
  components: {
    SmallNav,
    paystack,
    PageLoader
    // FooterMain,
  },
  data() {
    return {
      hideDiv: false,
      loading: false,
      amount: 5000,
      full_name: "",
      email: "",
      PUBLIC_KEY: staticdata.paystackkeylive,
      user: "",
      reference: "",
      subscribed: "",
      uploading: false,
      uploadFile: "",
      fileselected: false,
      filePath: "",
      progress: 0,
      file: "",
      headerText1: true,
      headerText2: false,
      headerText3: false,
      loader1: false
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.email = this.user.email;
    this.full_name = this.user.firstname + this.user.lastname;
    this.getSubscription();
  },
  // computed: {
  //   reference() {
  //     let text = "";
  //     const possible =
  //       "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //     for (let i = 0; i < 10; i++)
  //       text += possible.charAt(Math.floor(Math.random() * possible.length));
  //       console.log("Text from reference", text);
  //     return text;
  //   },
  // },
  methods: {
    // checked() {
    //   console.log("ddddddddddddd");
    // },
    processPayment(res) {
      // console.log("clikedd", res);
      const ref = res.reference;
      if (res.status === "success") {
        Api.postRequest(`makesubscription`, {
          amount: 5000,
          referencecode: ref
        })
          .then(res => {
            // console.log("Subscription response", res);
            if (res.data.success === "success") {
              this.getSubscription();
              return this.$store.commit(
                "setApiSuccess",
                "Subscription successful"
              );
            }
          })
          .catch(err => {
            console.log("err", err);
            // return this.$store.commit("setApiFailed", "Error occured");
          });
      }
      // console.log("refrence key", res, ref);
      // window.alert("Refrence key:", this.reference);
    },
    // Paystack close function or cancel payment
    close: () => {
      console.log("You closed checkout page");
    },
    async getSubscription() {
      this.hideDiv = true;
      try {
        await Api.getRequest(`checkifuserhassubscribed/${this.user.id}`)
          .then(res => {
            this.hideDiv = false;
            // console.log("Subscribed check", res);
            if (res.data.success === "success") {
              Api.userProfile();
              this.subscribed = res.data.paid;
            }
            // return this.$store.commit("setApiSuccess", "Sub success");
          })
          .catch(err => {
            // console.log("err", err);
            this.hideDiv = false;
            return this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("closed");
      }
    },
    payFromBank() {
      if (this.subscribed === "YES") {
        return this.$store.commit("setApiFailed", "You already subscribed");
      } else {
        $("#showpayment")
          .addClass("fade")
          .modal("show");
      }
    },
    payFromSafe() {
      if (this.subscribed === "YES") {
        return this.$store.commit("setApiFailed", "You already scubscribed");
      } else {
        $("#paymentSafe")
          .addClass("fade")
          .modal("show");
      }
    },
    subscribeWithSafe() {
      this.loader1 = true;
      Api.postRequest(`subscriptionwithsafe`, {})
        .then(res => {
          console.log("res", res);
          this.loader1 = false;
          if (res.data.success) {
            return this.$store.commit("setApiSuccess", "Success");
          }
          if (res.data.error) {
            return this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          this.loader1 = false;
        });
    },
    switchView(event) {
      if (event === 1) {
        document.getElementById("onee").style.display = "none";
        document.getElementById("twoo").style.display = "block";
        document.getElementById("threee").style.display = "none";
        this.headerText1 = false;
        this.headerText2 = true;
        this.headerText3 = false;
      } else if (event === 2) {
        document.getElementById("onee").style.display = "none";
        document.getElementById("twoo").style.display = "none";
        document.getElementById("threee").style.display = "block";
        this.headerText1 = false;
        this.headerText2 = false;
        this.headerText3 = true;
      } else if (event === 0) {
        document.getElementById("onee").style.display = "block";
        document.getElementById("twoo").style.display = "none";
        document.getElementById("threee").style.display = "none";
        // this.headerText2 = false;
      } else {
        document.getElementById("twoo").style.display = "none";
        document.getElementById("onee").style.display = "none";
        document.getElementById("threee").style.display = "block";
      }
    },

    selectFile() {
      const file = this.$refs.file.files[0];
      this.file = file;
      this.filePath = URL.createObjectURL(file);
      this.fileName = file.name;
      // console.log("fileeeeeeeeeeee", this.file);
      if (this.file) {
        this.fileselected = true;
      } else {
        this.fileselected = false;
      }
    },
    async sendFile() {
      // const file = this.$refs.file.files[0];
      // this.file = file;
      if (!this.file) {
        return this.$store.commit("setApiFailed", "No file was selected!");
      }
      const formData = new FormData();
      formData.append("file", this.file);
      try {
        this.uploading = true;
        this.loading = true;
        await Api.postRequest(`uploadsubscriptionpaymentslip`, formData, {
          onUploadProgress: e =>
            (this.progress = Math.round((e.loaded * 100) / e.total))
        })
          .then(res => {
            this.filenameRes = res.data.filename;
            this.uploading = true;
            this.uploadReceipt();
          })
          .catch(err => {
            this.loading = false;
            this.uploading = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    async uploadReceipt() {
      // this.loading = true;
      try {
        await Api.postRequest("makesubscriptionviatransfer", {
          amount: this.amount,
          filename: this.filenameRes
        })
          .then(res => {
            if (res.data.success) {
              this.switchView(2);
              this.uploading = false;
              this.loading = false;
              this.$store.commit("setApiSuccess", "Success");
            }
            if (res.data.error) {
              this.loading = false;
              this.uploading = false;
              this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.uploading = false;
            this.loading = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$yellow: #ffb100;
$black: #000000;
$black2: #222222;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.holder {
  height: 100%;
}

.flip_column {
  flex-direction: column-reverse !important;
  margin-left: 5px;
  margin-right: 5px;
}

.row_check {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

// SAFE payment conformation starts
.subConfirm {
  color: $black;
  font-family: Gotham;
  font-size: 19px;
  margin-bottom: 0;
}
.proceed-text {
  h6 {
    color: #555c74;
    font-family: Gotham;
    font-style: normal;
    font-size: 25px;
    padding-top: 40px;
  }
}
.confrim-safe-btn {
  padding-bottom: 40px;
  padding-top: 20px;
  button {
    font-family: Gotham;
    font-size: 16px;
    width: 100px;
    box-shadow: none;
    outline: 0;
  }
  button:first-child {
    border: 1px solid #eb0000;
    color: #eb0000;
    margin-right: 20px;
  }
  button:nth-child(2) {
    background: $primary;
    color: $secondary;
  }
}
// SAFE payment conformation ends

.payment_wrap {
  background: linear-gradient(0deg, #0033ea, #0033ea),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid #e5e5e5;
  padding-top: 47px;
}

.card {
  border-radius: 10px;
}
.sub_amount {
  margin-left: 49px;
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 43px;
    color: $secondary;
    text-align: left;
    img {
      margin-bottom: 20px;
    }
  }
  h4 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 33px;
    color: $secondary;
    text-align: left;
    text-decoration-line: line-through;
  }
}

.payment {
  background: $primary;
  // height: 388px;
  padding-top: 15px;
  padding-bottom: 10px;
  // border: 0.5px solid #E5E5E5;
  // border-radius: 5px;
  small {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-left: 10px;
  }
  div:first-child {
    cursor: pointer;
    small {
      color: #56c1f2;
    }
  }
  div:first-child:hover {
    cursor: pointer;
    small {
      color: #44aad8;
      font-size: 15px;
    }
  }

  .paystackBtn {
    color: #56c1f2;
    border: none;
    background: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    // margin-left: 10px;
    box-shadow: none;
    outline: 0;
    max-width: 262px;
    width: 100%;
    margin-bottom: 10px;
    height: 51px;
    position: relative;
    img:nth-child(3) {
      position: absolute;
      right: 6%;
    }
  }
  .paystackBtn:hover {
    color: #44aad8;
    font-size: 15px;
    cursor: pointer;
  }

  div:nth-child(2) {
    cursor: pointer;
    img:first-child {
      background: $primary;
      border-radius: 4px;
      width: 34px;
      padding: 5px;
    }
    small {
      color: $primary;
    }
    img:nth-child(3) {
      position: absolute;
      right: 6%;
    }
  }

  div:nth-child(3) {
    small {
      color: #febf12;
    }
    img:nth-child(3) {
      position: absolute;
      right: 6%;
    }
  }
  div:nth-child(4) {
    small {
      color: #16780d;
    }
    img:nth-child(3) {
      position: absolute;
      right: 6%;
    }
  }
  div:nth-child(5) {
    height: 50px;
    cursor: pointer;
    img:first-child {
      width: 10%;
      margin-right: 9px;
    }
    small {
      color: $primary;
    }
    img:nth-child(3) {
      position: absolute;
      right: 6%;
    }
  }
}

.paystack_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: inherit;
  padding-left: 5px;
}

.type_paystack {
  display: flex;
  justify-content: flex-start;
  background: #fff;
  width: 262px;
  // height: 52px;
  margin: auto;
  margin-bottom: 10px;
  padding: 10px;
  align-items: center;
  position: relative;
}

.sub_quote {
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    align-items: center;
    color: #ffffff;
    margin-bottom: 0;
    padding-bottom: 22px;
    cursor: pointer;
  }
}

.enjoy_sub {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid #e5e5e5;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.25);
  border-radius: 0px 10px 10px 0;
  height: 100%;
}

.enjoy_holder {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 9px;
  position: relative;
  // margin-bottom: 44px;
  // margin-bottom: 9px;
  div:first-child {
    h2 {
      font-family: Gotham;
      font-style: normal;
      font-weight: 700;
      font-size: 70px;
      line-height: 75px;
      display: flex;
      align-items: center;
      color: $primary;
      text-align: left;
    }
    p {
      font-family: Gotham;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 17px;
      color: $primary;
      margin-top: 25px;
    }
  }
  div {
    position: relative;
    img:first-child {
      width: 100%;
    }
    img:nth-child(2) {
      position: absolute;
      right: 10%;
      top: 5%;
    }
  }
}

// Sunscription Benefits
.sub_benefit_holder {
  margin-top: 40px;
  padding-bottom: 40px;
}

.sub_benefit_header {
  background: linear-gradient(0deg, #0033ea, #0033ea), #ffffff;
  border-radius: 10px 10px 0px 0px;
  h4 {
    padding: 17px 15px 9px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    color: $secondary;
    margin-bottom: 0;
    text-align: left;
  }
}
.benefit_body {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid #e5e5e5;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
}
.benefit {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 20px;
  p {
    // width: 60px;
    // height: 60px;
    width: 40px;
    height: 40px;
    color: $secondary;
    background: $primary;
    // padding: 10px 30px;
    padding: 10px 20px;
    border-radius: 50%;
    display: flex;
    margin-bottom: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    // font-size: 38px;
    // line-height: 18px;
    margin-right: 18px;
    margin-left: 18px;
    align-items: center;
    justify-content: center;
  }
  div:nth-child(2) {
    margin-right: 60px;
    h4 {
      // margin-top: 50px;
      font-family: Gotham;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: justify;
      color: $black;
    }
    h6 {
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: justify;
      color: $black;
    }
  }
}

.notes {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;
  h4 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: justify;
    color: $black;
  }
  h6 {
    font-family: Gotham;
    font-style: italic;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: justify;
    color: $black;
    span {
      color: #ea0000;
      margin-right: 8px;
      font-size: 20px;
    }
  }
}
// Subscription Benefits end

// Bank transfer upload modal
.payment_header_holder {
  background: $primary;
}

.payment-header {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
  color: $secondary;
}

.close_times {
  color: #ea0000;
  background: $secondary;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0px 6px;
}

.dot-holder {
  text-align: left;
  margin-top: -10px;
  margin-bottom: 10px;
}

.double_arrow_holder {
  p {
    margin-top: 5px;
    color: $primary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
  }
}

.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.step.active {
  // opacity: 1;
  background-color: $primary;
}
.step.finish {
  background-color: #04aa6d;
}

#twoo {
  display: none;
}
#threee {
  display: none;
}

//  First step starts here
.number__holder {
  text-align: left;
  display: flex;
  p:first-child {
    background: $primary;
    // padding: 0.3rem 0.69rem;
    border-radius: 50px;
    color: $secondary;
    text-align: center;
    align-items: baseline;
    margin-right: 16px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.5rem;
    line-height: 11px;
    // width: 2rem;
    // height: 1.5rem;
  }
  p:nth-child(2) {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 22px;
    color: $black;
    margin-right: 1rem;
  }
}

.sheltaAccount__details {
  background: rgba(64, 123, 255, 0.46);
  margin: 0 -16px 15px -16px;
  // margin-bottom: 15px;
  div {
    display: flex;
    justify-content: space-between;
    margin: 0 3.5rem;
    padding-top: 10px;
    p:first-child {
      font-family: Gotham;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      color: $black;
      text-align: left;
      margin-bottom: 0;
    }

    p:nth-child(2) {
      font-family: Gotham;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: $black;
      text-align: right;
      margin-bottom: 0;
    }
  }
}

.next-btn {
  background: $primary;
  border-radius: 5px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: $secondary;
  margin-bottom: 1rem;
  margin-top: 2rem;
  box-shadow: none;
}

.desclaimer__text {
  // padding-top: 4rem;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    color: $primary;
    text-align: left;
    margin-left: 2.5rem;
  }
}

.modal-foot {
  border-top: none;
}
// Firts modal steps ends

// Secosn modal steo start
.info__text {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: $black;
  text-align: left;
}

.dateOf__payment {
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    color: $black;
  }
  input {
    width: 100%;
    border-bottom: 0.5px solid #6c6c6c !important;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
}

.upload__file {
  .dropzone {
    border: 0.5px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 20px 0;
    padding: 50px 5px;
    min-height: 200px;
    cursor: pointer;
    position: relative;
    p {
      margin-bottom: 0;
      padding-top: 10px;
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      color: $black;
    }
    .browse__span {
      color: $primary;
    }
  }
}
.dropzone:hover {
  background-color: lightgrey;
}

// .input-file {
//   opacity: 0;
//   width: 100%;
//   left: 0%;
//   height: 200px;
//   position: absolute;
//   cursor: pointer;
// }
.input-file {
  opacity: 0;
  width: 100%;
  top: 0;
  left: 0%;
  // height: 200px;
  position: absolute;
  height: 100%;
  object-fit: contain;
  z-index: 10;
  cursor: pointer;
}

.actual_image {
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
  position: absolute;
  left: 0%;
  cursor: pointer;
}

.third-step {
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    // display: flex;
    // align-items: flex-end;
    text-align: center;
    color: $black;
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
}

.call-btn {
  background: #f2f5fe;
  border: 0.5px solid #6c6c6c;
  box-sizing: border-box;
  border-radius: 10px;

  // width: 154px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  box-shadow: none;
  // display: flex;
  // align-items: flex-end;
  text-align: center;
  color: $black;
  padding: 6px 26px;
  a {
    img {
      width: 27.01px;
      margin-right: 1rem;
    }
  }
}

.back__arrow {
  padding-top: 0.7rem;
  cursor: pointer;
  margin-right: 10px;
}

// Second modal step ends

// Bank transfer modal upload ends

@media screen and (max-width: 599px) {
  .enjoy_text_wrap {
    margin-left: 15px;
  }
  .enjoy_holder {
    div:first-child {
      h2 {
        font-size: 40px;
        line-height: 50px;
      }
      p {
        font-size: 10px;
        margin-top: 5px;
      }
    }
    div {
      // position: relative;
      // img:first-child {
      //     width: 100%;
      // }
      img:nth-child(2) {
        // position: absolute;
        // right: 2%;
        // top: -20%;
        display: none;
      }
    }
  }
  .row_wrap {
    flex-direction: column-reverse;
  }
  .sub_benefit_holder {
    margin-top: 26px;
  }
  .enjoy_sub {
    border-radius: 10px 10px 0px 0;
  }

  .sub_benefit_holder {
    padding-bottom: 0;
  }
  .benefit {
    margin-top: 20px;
    p {
      width: 30px;
      height: 30px;
      padding: 6px 14px;
      font-size: 16px;
      margin-right: 8px;
      margin-left: 8px;
    }
    div:nth-child(2) {
      margin-right: 15px;
      h4 {
        text-align: left;
        font-size: 13px;
        line-height: 22px;
      }
      h6 {
        font-size: 12px;
        text-align: left;
        line-height: 22px;
      }
    }
  }
  .sub_benefit_header {
    h4 {
      font-size: 18px;
    }
  }

  // Bank transfer
  .info__text {
    text-align: justify;
  }
  .number__holder {
    p:nth-child(2) {
      line-height: 23px;
      text-align: left;
      font-size: 13px;
    }
  }
  .sheltaAccount__details {
    div {
      margin: 5px 15px;
      p:first-child {
        font-size: 13px;
      }
      p:nth-child(2) {
        font-size: 13px;
      }
    }
  }
  .second__number {
    padding-top: 2rem;
  }
  .desclaimer__text {
    // padding-top: 1rem;
    p {
      font-size: 13px;
      line-height: 25px;
      text-align: left;
    }
  }
  .payment-header {
    font-size: 15px;
  }
  .third-step {
    p {
      font-size: 13px;
      line-height: 24px;
      text-align: left;
      padding-top: 2rem;
    }
  }
  .call-btn {
    font-size: 13px;
    padding: 6px 34px;
    a {
      img {
        margin-right: 2rem;
        width: 20.01px;
      }
    }
  }
  .third-step {
    img {
      width: 7rem;
    }
  }
  // Bank transfer
}
</style>
